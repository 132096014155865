import { useEffect, FC } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Avatar, Button, Box, Divider, Drawer, Typography } from '@mui/material'
import type { Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useAuth } from '@hooks'
import toast from 'react-hot-toast'
import Logo from '../Logo'
import {
	ClipboardListIcon,
	ChartSquareBarIcon,
	ChatAltIcon,
	UsersIcon,
	BriefcaseIcon,
	InformationCircleIcon
} from '@icons'
import NavSection from './NavSection'
import Scrollbar from '../Scrollbar'
import { useMsal } from '@azure/msal-react'
import { AuthType } from '@types'
import { useTranslation } from 'react-i18next'
import { version } from '../../../package.json'
import AssignmentIcon from '@mui/icons-material/Assignment'
import GroupsIcon from '@mui/icons-material/Groups'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import BadgeIcon from '@mui/icons-material/Badge'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

interface SidebarProps {
	onMobileClose: () => void
	openMobile: boolean
}

const Sidebar: FC<SidebarProps> = (props) => {
	const { onMobileClose, openMobile } = props
	const location = useLocation()
	const navigate = useNavigate()
	const { user, logout, type } = useAuth()
	const { instance } = useMsal()
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
	const { t } = useTranslation()

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose()
		}
	}, [location.pathname])

	const sections = [
		{
			title: t('General'),
			items: [
				{
					title: t('Overview'),
					path: '/',
					icon: <ChartSquareBarIcon fontSize="small" />
				},
				{
					title: t('Administration'),
					path: '/admin',
					icon: <ClipboardListIcon fontSize="small" />,
					requiredRole: 'AdminMenuItemViewer',
					children: [
						{
							title: t('Users'),
							path: 'administration/users',
							requiredRole: 'UsersViewer',
							icon: <UsersIcon fontSize="small" />
						},
						{
							title: t('Roles'),
							path: 'administration/roles',
							requiredRole: 'RolesViewer',
							icon: <BadgeIcon fontSize="small" />
						},
						{
							title: t('Role groups'),
							path: 'administration/role-groups',
							requiredRole: 'RoleGroupsViewer',
							icon: <GroupsIcon fontSize="small" />
						}
					]
				},
				{
					title: t('Soil Trade'),
					path: '/settings',
					icon: <BriefcaseIcon fontSize="small" />,
					children: [
						{
							title: t('New Work Order'),
							path: '/soil-trade/work-orders/create',
							icon: <NoteAltIcon fontSize="small" />
						},
						{
							title: t('Work Order Overview'),
							path: '/soil-trade/work-orders',
							icon: <AssignmentIcon fontSize="small" />
						},
						{
							title: t('Work Order Lines'),
							path: '/soil-trade/work-orders/work-order-lines',
							icon: <AssignmentIcon fontSize="small" />
						}
					]
				},
				{
					title: t('Transport'),
					path: '/transport',
					icon: <LocalShippingIcon fontSize="small" />,
					children: [
						{
							title: t('Transport overview'),
							path: '/transports',
							icon: <LocalShippingIcon fontSize="small" />
						}
					]
				}
			]
		},
		{
			title: t('other'),
			items: [
				{
					title: t("What's new"),
					path: '/whats-new',
					icon: <InformationCircleIcon fontSize="small" />
				},
				{
					title: t('Help Page'),
					path: '/help-page',
					icon: <ChatAltIcon fontSize="small" />
				}
			]
		}
	]

	const handleLogout = async (): Promise<void> => {
		try {
			switch (type) {
				case AuthType.Local:
					await logout()
					break
				case AuthType.Microsoft:
					localStorage.removeItem('user')
					await instance.logoutPopup({
						postLogoutRedirectUri: '/',
						mainWindowRedirectUri: '/'
					})
					break
			}

			navigate('/')
		} catch (err) {
			console.error(err)
			toast.error('Unable to logout.')
		}
	}

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Box
					sx={{
						display: {
							lg: 'none',
							xs: 'flex'
						},
						justifyContent: 'center',
						p: 2
					}}>
					<Logo />
				</Box>
				<Box sx={{ p: 2 }}>
					<Box
						sx={{
							alignItems: 'center',
							backgroundColor: 'background.default',
							borderRadius: 1,
							display: 'flex',
							overflow: 'hidden',
							p: 1
						}}>
						<RouterLink to="/account">
							<Avatar
								src={user.avatar}
								sx={{
									cursor: 'pointer',
									height: 44,
									width: 48
								}}
							/>
						</RouterLink>
						<Box sx={{ ml: 2 }}>
							<Typography color="textPrimary" variant="subtitle2">
								{user.name}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Divider />
				<Box sx={{ p: 2 }}>
					{sections.map(function (section) {
						return (
							<NavSection
								key={section.title}
								pathname={location.pathname}
								sx={{
									'& + &': {
										mt: 3
									}
								}}
								{...section}
							/>
						)
					})}
				</Box>
			</Scrollbar>
			<Box sx={{ p: 2 }}>
				<Typography style={{ fontSize: '11px' }}>
					{t('Copyright')}{' '}
					<a target="_blank" rel="noreferrer" href="https://www.growteq.nl/">
						{'Growteq'}
					</a>
					&copy; 2015 - {new Date().getFullYear()}
				</Typography>
				<Typography style={{ fontSize: '11px' }}>
					{t('Application version')} {version}
				</Typography>

				<Button color="primary" fullWidth sx={{ mt: 2 }} onClick={handleLogout} variant="contained">
					{t('Logout')}
				</Button>
			</Box>
		</Box>
	)

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'background.paper',
						height: 'calc(100% - 64px) !important',
						top: '64px !Important',
						width: 240
					}
				}}
				variant="permanent">
				{content}
			</Drawer>
		)
	}

	return (
		<Drawer
			anchor="left"
			onClose={onMobileClose}
			open={openMobile}
			PaperProps={{
				sx: {
					backgroundColor: 'background.paper',
					width: 240
				}
			}}
			variant="temporary">
			{content}
		</Drawer>
	)
}

Sidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
}

export default Sidebar
