/* eslint-disable prettier/prettier */
import { Suspense, lazy } from 'react'
import type { RouteObject } from 'react-router'
import { AuthGuard, GuestGuard } from '@components/guards'
import Layout from '@components/layout'
import LoadingScreen from '@components/LoadingScreen'

const Loadable = (Component) => (props) =>
(
	<Suspense fallback={<LoadingScreen />}>
		<Component {...props} />
	</Suspense>
)

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')))
const Register = Loadable(lazy(() => import('./pages/authentication/Register')))
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')))

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/Account')))
const Overview = Loadable(lazy(() => import('./pages/Overview')))

// Users page
const UsersList = Loadable(lazy(() => import('./pages/users/UsersList')))
const RolesList = Loadable(lazy(() => import('./pages/roles/RolesList')))
const RoleGroupsList = Loadable(lazy(() => import('./pages/roleGroups/RoleGroupsList')))
const UserDetails = Loadable(lazy(() => import('./pages/users/UserDetailsPage')))
const RoleGroupDetails = Loadable(lazy(() => import('./pages/roleGroups/RoleGroupDetails')))

//Soil Trade
const WorkOrderList = Loadable(lazy(() => import('./pages/workOrders/WorkOrdersList')))
const WorkOrderCreate = Loadable(lazy(() => import('./pages/workOrders/WorkOrderCreate')))
const WorkOrderLinesList = Loadable(lazy(() => import('./pages/workOrders/WorkOrderLinesList')))

//What's new
const WhatsNew = Loadable(lazy(() => import('./pages/whatsNew/WhatsNew')))

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

//Help pages
const HelpPage = Loadable(lazy(() => import('./pages/helpPage/HelpPage')))

// Transport pages
const TransportList = Loadable(lazy(() => import('./pages/transports/TransportList')))

const routes: RouteObject[] = [
	{
		path: 'authentication',
		children: [
			{
				path: 'login',
				element: (
					<GuestGuard>
						<Login />
					</GuestGuard>
				)
			},
			{
				path: 'password-recovery',
				element: <PasswordRecovery />
			},
			{
				path: 'password-reset',
				element: <PasswordReset />
			},
			{
				path: 'register',
				element: (
					<GuestGuard>
						<Register />
					</GuestGuard>
				)
			},
			{
				path: 'verify-code',
				element: <VerifyCode />
			}
		]
	},
	{
		path: '*',
		element: (
			<AuthGuard>
				<Layout />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <Overview />
			},
			{
				path: 'account',
				element: <Account />
			},
			{
				path: '',
				children: [
					{
						path: 'administration/users',
						element: <UsersList />
					},
					{
						path: 'administration/users/:userId',
						element: <UserDetails />
					},
					{
						path: 'administration/roles',
						element: <RolesList />
					},
					{
						path: 'administration/role-groups',
						element: <RoleGroupsList />
					},
					{
						path: 'administration/role-groups/:roleGroupId',
						element: <RoleGroupDetails />
					},
					{
						path: 'soil-trade/work-orders',
						element: <WorkOrderList />
					},
					{
						path: 'soil-trade/work-orders/work-order-lines/:workOrderId',
						element: <WorkOrderLinesList />
					},
					{
						path: 'soil-trade/work-orders/work-order-lines',
						element: <WorkOrderLinesList />
					},
					{
						path: 'soil-trade/work-orders/create',
						element: <WorkOrderCreate />
					},
					{
						path: 'transports',
						element: <TransportList />
					},
					{
						path: 'help-page',
						element: <HelpPage />
					}
				]
			},
			{
				path: 'whats-new',
				element: <WhatsNew />
			},
			{
				path: '404',
				element: <NotFound />
			},
			{
				path: '500',
				element: <ServerError />
			},
			{
				path: '*',
				element: <NotFound />
			}
		]
	},
	{
		path: '401',
		element: <AuthorizationRequired />
	}
]

export default routes